import React, { useEffect, useState } from "react";
import bottomLogo from "./assets/bottomLogo.svg";
import "./App.css";
import HomeScreen from "./pages/HomeScreen/HomeScreen";

import "react-toastify/dist/ReactToastify.css";
// import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";

function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 70);

    return () => clearInterval(interval);
  }, [count]);
  return (
    <>
      {/* {count >= 100 ? ( */}
      <div className="mx-auto h-full max-w-[378px] relative font-poppins]">
        <div className="shadow flex flex-col scrollbar-hide h-full min-h-screen bg-[#FBFDFF] home-screen">
          <HomeScreen />
        </div>
      </div>
      {/* ) : (
        <LoadingScreen count={count} />
      )} */}
    </>
  );
}

export default App;
