import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import { load } from "@cashfreepayments/cashfree-js";
import gPay from "../../assets/googlePay.png";
import paytm from "../../assets/paytmSvg.svg";
import phonepe from "../../assets/phonepe.png";
import upi from "../../assets/upi.png";
import Select from "react-select";
import { initiateUpi } from "./upiUtility";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";
const Upi = ({
  paymentId,
  payButton,
  childComponentFunctionRef,
  payRef,
  collect_request_id,
  provider,
  prov_id,
  finalAmountWithMDR,
  isBlank,
  setUpiProvider,
  setUpiProviderId,
  isBuzz,
  easebuzzPaymentId,
  isEasebuzzSchool,
  setOpenWindow,
}) => {
  // const [provider, setProvider] = useState("");
  const [vpaId, setVpaId] = useState("");

  const payButtonRef = useRef(null);
  const upiInputRef = useRef(null);
  const validUpiRef = useRef(null);
  const [validUpi, setValidUpi] = useState(false);
  const mountingRef = useRef(null);
  const vpainputRef = useRef(null);

  const [providerId, setProviderId] = useState(prov_id);
  const gpayRef = useRef(null);
  const paytmRef = useRef(null);
  const phonepeRef = useRef(null);
  const otherRef = useRef(null);
  const [cashfree, setCashfree] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [phonePeIntent, setphonePeintent] = useState("");
  const [goglePayIntent, setgoglePayIntent] = useState("");
  const [paytmIntent, setpaytmIntent] = useState("");
  const { isMobile } = useDeviceDetect();
  const getIntent = async () => {
    try {
      const intents = await axios.get(
        `${process.env.REACT_APP_PAYMENT_BACKEND}/easebuzz/upiqr?collect_id=${collect_request_id}`
      );
      setphonePeintent(intents.data.phonePe);
      setgoglePayIntent(intents.data.googlePe);
      setpaytmIntent(intents.data.paytm);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getIntent();
  }, [isEasebuzzSchool]);

  let upiCollect;
  const upiOptions = {
    paytm: ["@paytm", "@pthdfc", "@ptsbi", "@ptyes", "@ptaxis"],
    gpay: ["@okicici", "@okaxis", "@oksbi", "@okhdfcbank"],
    phonepe: ["@axl", "@ybl", "@ibl"],
  };
  const icons = {
    paytm: paytm,
    gpay: gPay,
    phonepe: phonepe,
  };

  const name = {
    paytm: "Paytm",
    gpay: "Google Pay",
    phonepe: "PhonePe",
    other: "Other",
  };
  const reference = {
    paytm: paytmRef,
    gpay: gpayRef,
    phonepe: phonepeRef,
    other: otherRef,
  };

  const isValidUpiId = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/.test(vpaId);

  useEffect(() => {
    setProviderId(prov_id);
    // setProvider(provider);
  }, [prov_id]);

  useEffect(() => {
    finalAmountWithMDR("upi", "Others");
  }, []);

  useEffect(() => {
    var initializeSDK = async function () {
      let cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      setCashfree(cashfree);
    };

    initializeSDK();
  }, []);

  useEffect(() => {
    if (cashfree && provider !== "other") {
      doMobilePayment(provider);
    }
  }, [cashfree, provider]);

  const initPay = (comp) => {
    cashfree
      .pay({
        paymentMethod: comp,
        paymentSessionId: paymentId,
        redirectTarget: isBlank === "true" ? "_blank" : "_self",

        //returnUrl: "http://localhost:3000/{order_id}",
        returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
      })
      .then(function (data) {
        setVpaId("");

        if (data.error) {
        }
      });
  };

  const doMobilePayment = async (provider) => {
    const upiApp = cashfree.create("upiApp", {
      values: {
        upiApp: provider,
        buttonText: "Paytm",
        buttonIcon: true,
      },
      style: {
        base: {
          color: "#717171",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
        },
      },
    });
    upiApp.mount("#mounting-point");
    upiApp.on("ready", () => {
      initPay(upiApp);
    });
  };
  const doPayment = (vpaId) => {
    vpaId = providerId ? vpaId.split("@")[0] + providerId : vpaId;

    upiCollect = cashfree.create("upiCollect", {
      values: {
        upiId: vpaId,
        buttonText: "Pay",
      },
      style: {
        base: {
          fontSize: "22px",
          display: "none",
        },
      },
    });

    upiCollect.mount("#mounting-point");

    upiCollect.on("loaderror", (data) => {
      console.error("Load error:", data.error, data.value);
      toast.error("Internal Server Error");
    });

    upiCollect.on("ready", () => {
      //  setVpaId("");

      // payRef.current.disabled = true;
      if (upiCollect.isComplete()) {
        cashfree
          .pay({
            paymentMethod: upiCollect,
            paymentSessionId: paymentId,
            returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
          })
          .then(function (data) {
            // setVpaId("");
            //  upiCollect.unmount()
            if (data.error) {
              // setVpaId("");

              toast.error(data.error.message);
            }
          });
      }
    });
  };

  useEffect(() => {
    if (provider !== "other") {
      payRef.current.disabled = vpaId.length >= 3 ? false : true;
    } else {
      payRef.current.disabled = isValidUpiId ? false : true;
    }
  }, [vpaId]);

  childComponentFunctionRef.current = () => {
    if (isEasebuzzSchool || localStorage.getItem("isBhavan") === "true") {
      const upiId = providerId ? vpaId.split("@")[0] + providerId : vpaId;
      initiateUpi({
        payment_id: easebuzzPaymentId,
        upi_id: upiId,
      });
    } else {
      doPayment(vpaId);
    }
  };
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const handleRedirect = (event) => {
    const { easebuzz, upiProvider, upiProviderId } = JSON.parse(
      event.target.value
    );
    if (easebuzz !== "other") {
      setUpiProvider(upiProvider);
      setProviderId(upiProviderId);
      let url = "";
      switch (easebuzz) {
        case "phonePe":
          url = phonePeIntent;
          break;
        case "googlePay":
          url = goglePayIntent;
          break;
        case "paytm":
          url = paytmIntent;
          break;
        default:
          break;
      }
      if (url) {
        window.location.href = url;
      }
    } else {
      setUpiProvider(upiProvider);
      setProviderId(upiProviderId);
    }
  };
  return (
    <>
      {provider !== "other" ? (
        <div className="flex m-4 gap-x-4">
          <div className="flex justify-center items-center min-w-[110px] h-[40px] py-1.5 shadow-black-shadow rounded-lg">
            <img
              src={icons[provider]}
              className="w-[32px] object-contain mr-2"
              alt="paymentmode"
            />
          </div>
          <div className="flex flex-col">
            <p className=" text-[12px] text-black font-semibold ">
              {name[provider]}{" "}
            </p>
            <p className=" text-[9px] font-normal text-[#717171]">
              link your {provider} account
            </p>
          </div>
          <p
            onClick={() => setOpenWindow(false)}
            className="text-[9px] underline text-[#505E8F] ml-auto"
          >
            Close
          </p>
        </div>
      ) : (
        <>
          <div className="flex  m-4 gap-x-4">
            <div className="flex justify-center items-center min-w-[110px] h-[40px] py-1.5 shadow-black-shadow rounded-lg">
              <img
                src={upi}
                className="w-[32px] object-contain mr-2"
                alt="paymentmode"
              />
            </div>
            <div className="flex flex-col">
              <p className=" text-[12px] text-black font-semibold ">
                UPI Payment
              </p>
              <p className=" text-[9px] font-normal text-[#717171]">
                link your UPI account
              </p>
            </div>
            <p
              onClick={() => setOpenWindow(false)}
              className="text-[9px] underline text-[#505E8F] ml-auto"
            >
              Close
            </p>
          </div>
        </>
      )}
      <div className={"px-4 mb-4 pb-0 " + (menuIsOpen ? "h-80" : "h-auto")}>
        <div className="flex flex-col w-full h-full">
          <label
            className="font-semibold text-[12px] pl-2"
            htmlFor="bankDropdown"
          >
            Choose UPI:
          </label>
          {isEasebuzzSchool && isMobile === true ? (
            <div className="border flex mt-2 justify-between items-center rounded-lg px-3 py-2 ">
              <select
                onChange={handleRedirect}
                className=" appearance-none flex-1 bg-transparent focus:outline-none"
              >
                <option
                  value={JSON.stringify({
                    easebuzz: "phonePe",
                    upiProvider: "phonepe",
                    upiProviderId: "@axl",
                  })}
                >
                  PhonePe
                </option>
                <option
                  value={JSON.stringify({
                    easebuzz: "googlePay",
                    upiProvider: "gpay",
                    upiProviderId: "@okicici",
                  })}
                >
                  Google Pay
                </option>
                <option
                  value={JSON.stringify({
                    easebuzz: "paytm",
                    upiProvider: "paytm",
                    upiProviderId: "@paytm",
                  })}
                >
                  Paytm
                </option>
                <option
                  value={JSON.stringify({
                    easebuzz: "other",
                    upiProvider: "other",
                    upiProviderId: "",
                  })}
                >
                  Other
                </option>
              </select>
              <IoIosArrowDown className="size-4" />
            </div>
          ) : (
            <Select
              className=" font-normal p-2 z-[99999999] rounded-lg"
              id="bankDropdown"
              onChange={(e) => {
                setUpiProvider(e.setUpiProvider);
                setProviderId(e.setProviderId);
              }}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              options={[
                {
                  setUpiProvider: "phonepe",
                  setProviderId: "@axl",
                  value: "Phone Pe",
                  label: "Phone Pe",
                },
                {
                  setUpiProvider: "gpay",
                  setProviderId: "@okicici",
                  value: "Google Pay",
                  label: "Google Pay",
                },
                {
                  setUpiProvider: "paytm",
                  setProviderId: "@paytm",
                  value: "Paytm",
                  label: "Paytm",
                },
                {
                  setUpiProvider: "other",
                  setProviderId: "",
                  value: "Other",
                  label: "Other",
                },
              ]}
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#E8EBF6",
                  border: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  "::placeholder": {
                    backgroundColor: "#YourDesiredColor",
                    opacity: 1,
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  fontSize: "12px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "10px",
                }),
              }}
            />
          )}
        </div>
      </div>

      <div className="flex items-center shadow-black-shadow rounded-lg py-2 -mt-2 px-2 mb-4 ml-5  mr-5 w-auto ">
        <input
          type="text"
          placeholder="Enter VPA ID"
          className="  pl-1 outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-xs"
          ref={vpainputRef}
          onChange={(e) => {
            if (provider !== "other") {
              e.target.value = e.target.value.replace("@", "");
            }
            setVpaId(e.target.value);
            // let vpa = providerId ? vpaId.split("@")[0] + providerId : vpaId;
            // setVpaId(vpa);

            //setVpaId(providerId ? e.target.value + providerId : e.target.value);
          }}
        />
        {provider !== "other" && (
          <select
            value={providerId}
            onChange={(e) => {
              let ids = upiOptions[provider].filter(
                (option) => option === e.target.value
              );

              setProviderId(ids[0]);

              // setProviderIndex(e.target.value)
            }}
            className="w-40 bg-transparent px-5 text-[#717171] text-sm"
            id="vpas"
            name="vpas"
          >
            {upiOptions?.[provider]?.map((option) => (
              <option className="text-[#717171]" value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
      </div>

      <button
        ref={mountingRef}
        className="opacity-0 w-0 h-0"
        id="mounting-point"
      ></button>
      <button className="opacity-0 w-0 h-0" id="mounting-point-upiapp"></button>
    </>
  );
};
export default Upi;
